// main.ts
import "@fontsource/outfit";
import "fontsource/lora";
// import "@fontsource/alegreya-sans";
// import "@fontsource/source-code-pro";
import App from "./App.svelte";
import { register } from "swiper/element/bundle";
register();
const app = new App({
    target: document.body,
    props: {
    // your props here
    },
});
export default app;
