<!--
  Footer.svelte

  @description
    This component is responsible for rendering the footer of the page.
    It is fixed to the bottom of the page and contains the footer text.
    The text is centered and has a semi-transparent blur on top of the
    background and text.

-->
<script>
  // Footer-specific logic can go here, if needed.
  // Let's dynamically fetch the current year
  let currentYear = new Date().getFullYear();
  console.log(`Current year is ${currentYear}`);
</script>

<div class="footer">
  <p class="footer-text">
    Copyright &copy; Jaan-Pauli Kimpimäki {currentYear || 2024}. All rights
    reserved.
  </p>
</div>

<style>
  .footer {
    width: calc(100% - 20px); /* Accommodating scrollbar width */
    box-sizing: content-box;
    /* background-color: rgba(15, 23, 42, 0.4); /* Semi-transparent Slate 900 */
    backdrop-filter: blur(20px);
    text-align: center;
    padding: 6px 0;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .footer-text {
    color: #2c3e50; /* Slate 800 */
    margin: 0;
    padding: 0;
    font-size: 0.8em;
  }
</style>
