<!--
  FontFaces.svelte

  @description
    This component is responsible for loading the fonts from the font-config.js
    file and adding them to the DOM. This is a little unoptimized for the moment.

-->
<script>
  import { onMount } from "svelte";
  import { fontConfig } from "../../font-config.js";

  const formats = ["woff2", "woff"];

  onMount(() => {
    let cssString = "";

    const styles = [
      { style: "normal", postfix: "400-normal" },
      { style: "italic", postfix: "400-italic" },
    ];

    for (const [, fonts] of Object.entries(fontConfig)) {
      for (const [key, fontDetails] of Object.entries(fonts)) {
        const name = fontDetails.displayName;
        const pathKey = key.toLowerCase().replace(/\s+/g, "-");

        for (const style of styles) {
          const src = formats
            .map(
              (format) =>
                `url('/build/fonts/${pathKey}-latin-${style.postfix}.${format}') format('${format}')`
            )
            .join(", ");

          cssString += `
            @font-face {
              font-family: '${name}';
              src: ${src};
              font-style: ${style.style};
            }
          `;
        }
      }
    }

    const styleEl = document.createElement("style");
    styleEl.type = "text/css";
    styleEl.appendChild(document.createTextNode(cssString));
    document.head.appendChild(styleEl);
  });
</script>
