<!--
  Publications.svelte

  @description
    This component fetches the publication counts and list from the API endpoint
    and stores them in stores. It also updates the local storage with the latest
    values every 10 minutes of the page being open.

-->
<script>
  import { onMount } from "svelte";
  import {
    publicationCount,
    publicationList,
    isFetchingPubCount,
    isFetchingPubList,
    cachedPublicationCount,
    cachedPublicationList,
  } from "../stores/statisticsStore.js";

  $: console.log("Publication List:", $publicationList);

  async function fetchPublicationCounts() {
    const response = await fetch("/api/publication-counts");
    const data = await response.json();
    publicationCount.set(data.publicationCount);
    cachedPublicationCount.set(data.cachedPublicationCount);

    // Update local storage for publicationCount
    localStorage.setItem(
      "publicationCount",
      JSON.stringify(data.publicationCount)
    );
  }

  async function fetchData() {
    publicationList.set($cachedPublicationList);

    try {
      isFetchingPubCount.set(true);

      const publicationsResponse = await fetch("/api/publication-list");
      const publicationsData = await publicationsResponse.json();

      if (!publicationsData || !publicationsData.publicationList) {
        throw new Error("No publications data returned");
      }

      // Initialize with cached values
      publicationList.set($cachedPublicationList);

      // Consts
      const manualUrls = {
        "From observation to insight: Computational abduction and its applications in sustainable strategy research":
          "https://lutpub.lut.fi/handle/10024/166512",
        // More URLs can be added here...,
      };

      const doiPromises = publicationsData.publicationList.map(async (pub) => {
        // If pub.link is undefined, fetch it from the hardcoded links based on the title.
        if (!pub.link) {
          pub.link = manualUrls[pub.title] || "Link not available"; // Default to "Link not available" if not found in hardcodedLinks
        }

        // Only fetch a DOI if it's not already available.
        if (!pub.doi) {
          // Fetching logic...
          const response = await fetch(
            `/api/get-doi?title=${encodeURIComponent(
              pub.title
            )}&authors=${encodeURIComponent(
              pub.authors
            )}&year=${encodeURIComponent(pub.year)}`
          );
          const doiData = await response.json();
          if (doiData.doi) {
            pub.doi = doiData.doi; // Assign the new DOI
            pub.link = `http://doi.org/${doiData.doi}`; // Update the link
          } else {
            pub.doi = "DOI not available";
            // Keep the existing link if DOI fetch was unsuccessful
          }
        }

        // The structure of the returned object from this map operation stays consistent.
        return pub;
      });

      const updatedPublications = await Promise.all(doiPromises);
      publicationList.set(updatedPublications);

      // Update local storage for publicationList
      localStorage.setItem(
        "publicationList",
        JSON.stringify(updatedPublications)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      publicationList.set($cachedPublicationList);
    } finally {
      isFetchingPubCount.set(false);
      isFetchingPubList.set(false);
    }
  }

  onMount(async () => {
    console.log("Fetching publication counts and list");
    await fetchPublicationCounts();
    await fetchData();

    const intervalIDCounts = setInterval(
      fetchPublicationCounts,
      10 * 60 * 1000
    ); // 10 minutes
    const intervalIDList = setInterval(fetchData, 10 * 60 * 1000); // 10 minutes

    return () => {
      clearInterval(intervalIDCounts);
      clearInterval(intervalIDList);
    };
  });
</script>
