<!--
  NavBar.svelte

  @description
    This component is responsible for rendering the navigation bar at the left
    side of the page. It contains links to the different pages of the website,
    and is styled using TailwindCSS.

    The component imports the Link component from svelte-routing, which is
    responsible for handling the routing of the website. The Link component
    is used to create links to the different pages of the website.

-->


<script>
  import { Link } from "svelte-routing";
</script>

<div class="mt-8">
  <!-- Navbar -->
  <ul>
    <li>
      <Link
        to="/about"
        class="text-xl my-2 border-none cursor-pointer text-slate-300
    hover:text-emerald-400 text-left font-semibold"
      >
        About
      </Link>
    </li>
    <li>
      <Link
        to="/research"
        class="text-xl my-2 border-none cursor-pointer text-slate-300
    hover:text-emerald-400 text-left font-semibold"
      >
        Research
      </Link>
    </li>
    <li>
      <Link
        to="/annotations"
        class="text-xl my-2 border-none cursor-pointer text-slate-300
    hover:text-emerald-400 text-left font-semibold"
      >
        Annotations
      </Link>
    </li>
  </ul>
</div>

