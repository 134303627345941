import { writable } from "svelte/store";

export const isFetchingPubList = writable(true);
export const isFetchingCitations = writable(true);
export const isFetchingHIndex = writable(true);
export const isFetchingPubCount = writable(true);
export const cachedCitations = writable(null);
export const citations = writable(null);
export const cachedHIndex = writable(null);
export const hIndex = writable(null);
export const cachedPublicationCount = writable(null);
export const publicationCount = writable(null);
export const cachedPublicationList = writable([]);
export const publicationList = writable([]);
let currentCitations = null;
let currentHIndex = null;
let currentPublicationCount = null;
let currentPublicationList = null;

citations.subscribe((value) => {
  currentCitations = value;
  console.log("citations changed, new value:", value);
});

hIndex.subscribe((value) => {
  currentHIndex = value;
  console.log("hIndex changed, new value:", value);
});

publicationCount.subscribe((value) => {
  currentPublicationCount = value;
  console.log("publicationCount changed, new value:", value);
});

publicationList.subscribe((value) => {
  currentPublicationList = value;
  console.log("publicationList changed, new value:", value);
});

async function fetchCachedPublicationList() {
  try {
    const response = await fetch("api/cache-publication-list");
    const data = await response.json();
    cachedPublicationList.set(data.publicationList);

    if (
      currentPublicationList === null &&
      localStorage.getItem("publicationList") === null
    ) {
      publicationList.set(data.publicationList);
    }
  } catch (error) {
    console.log("Error fetching cached publication list:", error);
  } finally {
    isFetchingPubList.set(false);
  }
}

async function fetchCachedCitations() {
  try {
    const response = await fetch("api/cache-citations");
    const data = await response.json();
    cachedCitations.set(data.citations);

    if (
      currentCitations === null &&
      localStorage.getItem("citations") === null
    ) {
      citations.set(data.citations);
    }
  } catch (error) {
    console.log("Error fetching cached citations:", error);
  } finally {
    isFetchingCitations.set(false);
  }
}

async function fetchCachedHIndex() {
  try {
    const response = await fetch("api/cache-h-index");
    const data = await response.json();
    cachedHIndex.set(data.hIndex);

    if (currentHIndex === null && localStorage.getItem("hIndex") === null) {
      hIndex.set(data.hIndex);
    }
  } catch (error) {
    console.log("Error fetching cached h-index:", error);
  } finally {
    isFetchingHIndex.set(false);
  }
}

async function fetchCachedPublicationCount() {
  try {
    const response = await fetch("api/cache-publication-list");
    const data = await response.json();
    cachedPublicationCount.set(data.publicationCount);

    if (
      currentPublicationCount === null &&
      localStorage.getItem("publicationCount") === null
    ) {
      publicationCount.set(data.publicationCount);
    }
  } catch (error) {
    console.log("Error fetching cached publication count:", error);
  } finally {
    isFetchingPubCount.set(false);
  }
}

Promise.all([
  fetchCachedPublicationList(),
  fetchCachedCitations(),
  fetchCachedHIndex(),
  fetchCachedPublicationCount(),
])
  .then(() => {
    console.log("Cached values fetched successfully");
  })
  .catch((error) => {
    console.log("Error fetching cached values:", error);
  });

let localStoragePublicationList = null;
try {
  localStoragePublicationList = JSON.parse(
    localStorage.getItem("publicationList"),
  );
} catch (error) {
  console.error("Error parsing publicationList from localStorage:", error);
}
if (localStoragePublicationList !== null) {
  publicationList.set(localStoragePublicationList);
  isFetchingPubList.set(false);
}

let localStorageCitations = null;
try {
  localStorageCitations = JSON.parse(localStorage.getItem("citations"));
} catch (error) {
  console.error("Error parsing citations from localStorage:", error);
}
if (localStorageCitations !== null) {
  citations.set(localStorageCitations);
  isFetchingCitations.set(false);
}

let localStorageHIndex = null;
try {
  localStorageHIndex = JSON.parse(localStorage.getItem("hIndex"));
} catch (error) {
  console.error("Error parsing hIndex from localStorage:", error);
}
if (localStorageHIndex !== null) {
  hIndex.set(localStorageHIndex);
  isFetchingHIndex.set(false);
}

let localStoragePublicationCount = null;
try {
  localStoragePublicationCount = JSON.parse(
    localStorage.getItem("publicationCount"),
  );
} catch (error) {
  console.error("Error parsing publicationCount from localStorage:", error);
}
if (localStoragePublicationCount !== null) {
  publicationCount.set(localStoragePublicationCount);
  isFetchingPubCount.set(false);
}
