<!--
  Hindex.svelte

  @description
    This component fetches the h-index from the API endpoint and stores
    it in a store. It also updates the local storage with the latest values
    every 10 minutes of the page being open.

-->
<script>
  import { onMount } from "svelte";
  import { hIndex } from "../stores/statisticsStore.js";

  onMount(() => {
    const fetchData = () => {
      fetch("/api/h-index")
        .then((response) => response.json())
        .then((data) => {
          hIndex.set(data.hIndex);

          // Update local storage
          localStorage.setItem("hIndex", JSON.stringify(data.hIndex));
        });
    };

    fetchData(); // Call once on component mount

    const intervalID = setInterval(fetchData, 10 * 60 * 1000); // Call every 10 minutes

    return clearInterval(intervalID); // Clear interval on component unmount (optional)
  });
</script>
