<!--
  PubList.svelte

  @description
    This component renders the full list of publications inside the
    `Research.svelte` component. It uses the `publicationList` store
    to render the list of publications. The store is populated by
    the `Publications.svelte` component. The most recent three publications
    are also rendered as part of `Recents.svelte`.

-->
<script>
  import { publicationList } from "../stores/statisticsStore.js";
</script>

<div class="research-list">
  {#each $publicationList as pub}
    <a href={pub.link ? pub.link : "#"} class="publication-item">
      <h3 class="text-xl text-slate-400 font-bold"><span>{pub.title}</span></h3>
      <p class="text-md text-slate-300">{pub.authors}</p>
      <p class="text-sm text-slate-200">
        {pub.year},
        {pub.publicationDetails}
      </p>
      <p class="text-sm text-slate-400">
        {pub.citedBy} Google Scholar citations
      </p>
    </a>
  {/each}
</div>
