<!--
  BlogPostCarousel.svelte

  @description
    This component is the primary carousel for navigating between annotations.
    It contains a swiper component that allows for swiping between annotations,
    and a blog post component that renders the markdown for the selected annotation.
    It tracks state between the path URL and the selected index, and updates the
    URL on slide change and vice versa. It also contains a small header and
    description for the annotations page.

-->
<script>
  import { register } from "swiper/element/bundle";
  import { fade } from "svelte/transition";
  import BlogPost from "./BlogPost.svelte";
  import { onMount } from "svelte";
  register();

  export let initialUrl = "";

  let selectedIndex = 0;

  $: {
    if (initialUrl) {
      const index = blogPosts.findIndex((post) => post.path === initialUrl);
      if (index !== -1) {
        selectedIndex = index;
      }
    }
  }
  const blogPosts = [
    {
      title: "How I built this website",
      url: "/blogposts/post1.md",
      path: "/annotations/how-i-built-this-website",
    },
    {
      title: "Analysis Quickstart (AQ)",
      url: "/blogposts/post2.md",
      path: "/annotations/analysis-quickstart",
    },
    {
      title: "How I write papers",
      url: "/blogposts/post3.md",
      path: "/annotations/how-i-write-papers",
    },
  ];

  const onSlideChange = (e) => {
    selectedIndex = e.detail[0].activeIndex;
    const newPath = blogPosts[selectedIndex].path;
    history.replaceState({}, "", newPath);
  };

  onMount(() => {
    const scrollableDiv = document.getElementById("dynamic-content");
    if (window.innerWidth <= 1024) {
      // Or whatever your mobile breakpoint is
      // For smaller screens, scroll the whole window
      if (scrollableDiv) {
        const rect = scrollableDiv.getBoundingClientRect();
        window.scrollTo({
          top: rect.top + window.scrollY,
          behavior: "smooth",
        });
      }
    } else {
      // For larger screens, scroll only the div
      if (scrollableDiv) {
        scrollableDiv.scrollTop = 0;
      }
    }

    // Wait for the Swiper instance to be initialized
    setTimeout(() => {
      const swiperContainer = document.querySelector("swiper-container");

      if (swiperContainer && swiperContainer.swiper) {
        const index = blogPosts.findIndex((post) => post.path === initialUrl);
        if (index !== -1) {
          swiperContainer.swiper.slideTo(index);
        }
      }

      if (swiperContainer && swiperContainer.shadowRoot) {
        const nextButton = swiperContainer.shadowRoot.querySelector(
          ".swiper-button-next"
        );
        const prevButton = swiperContainer.shadowRoot.querySelector(
          ".swiper-button-prev"
        );

        if (nextButton && prevButton) {
          // Apply your styles here
          nextButton.style.color = "#94a3b8"; // Replace with your desired color
          prevButton.style.color = "#94a3b8"; // Replace with your desired color
        }
      }
    }, 0);
  });
</script>

<!-- Header for blogposts -->
<div class="flex justify-center items-center">
  <h1 class="text-5xl text-slate-200 font-bold">Annotations</h1>
</div>
<div class="flex justify-center items-center">
  <p class="text-xl text-slate-300 my-8 text-center">
    Collection of some of scribbles.
  </p>
</div>

<!-- Swiper -->
<swiper-container
  key={selectedIndex}
  navigation="true"
  on:slidechange={onSlideChange}
>
  {#each blogPosts as post}
    <swiper-slide>
      <span class="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
        {post.title}
      </span>
    </swiper-slide>
  {/each}
</swiper-container>

<div class="blogpost-container">
  {#each blogPosts as post, i}
    {#if i === selectedIndex}
      <div class="blogpost" transition:fade={{ delay: 100, duration: 250 }}>
        <BlogPost url={post.url} />
      </div>
    {/if}
  {/each}
</div>

<style>
  swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    @apply text-2xl text-slate-400 my-8;
  }

  .blogpost-container {
    position: relative;
    overflow: visible;
    min-height: 500px;
    padding-bottom: 5vh;
  }

  .blogpost {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: visible;
    padding-bottom: 15vh;
  }
</style>
