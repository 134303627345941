<!--
  ProfileText.svelte

  @description
    This component contains text for the profile section of the `About` page.
    This text is the basic entrypoint for the site, so should probably make it
    look a bit nicer. This is also the component that has the little animation
    for the socials link in the text.

-->
<script>
  import { Link } from "svelte-routing";
  import { highlightSocialsStore } from "../stores/socialsStore.js";

  function highlightSocials() {
    highlightSocialsStore.set(true);
  }

  function removeHighlight() {
    highlightSocialsStore.set(false);
  }
</script>

<div>
  <h2 class="text-6xl text-slate-300 font-bold mb-8">
    Welcome to my humble internet abode
  </h2>
  <p class="text-slate-300 mb-4">
    You've reached the internet home of <strong>Jaan-Pauli Kimpimäki</strong>.
  </p>
  <p class="text-slate-300 mb-4">
    On my off time I like to listen to old smokey jazz, read digitally dusty
    books, and play video games among other things. I am so far undecided on
    coffee vs. tea, both sides make good arguments. I enjoy both in tandem
    albeit not at the same time. If you're interested in my work, you can check
    out my mostly empty
    <strong
      ><a
        href="#socials"
        on:mouseover={highlightSocials}
        on:mouseout={removeHighlight}
        on:focus={highlightSocials}
        on:blur={removeHighlight}>socials</a
      ></strong
    >, take a look at my <strong><Link to="/research">research</Link></strong>
    page for details on my work and publications, or just
    <strong
      ><a href="mailto:jaanpauli.kimpimaki@gmail.com">swing me an email</a
      ></strong
    > directly. The bottom of this page shows a few of my latest publications for
    those academically inclined to be in a constant state of rush.
  </p>
  <p />
  <p class="text-slate-300 mb-4">
    For some random scribbles and thoughts I've jotted down, you can also check
    out my <strong><Link to="/annotations">annotations</Link></strong> page, which
    contains some ideas and activities I've thought worth writing about.
  </p>
</div>

<style>
  a {
    @apply text-slate-500 font-bold;
  }

  a:hover {
    @apply text-emerald-400 font-bold;
  }

  a:visited {
    @apply text-slate-400 font-bold;
  }
</style>
