<!--
  MiniProfile.svelte

  @description
    This component contains the mini-profile that is displayed on the left
    side of the page. It contains a short abstract of myself, as well as
    keywords relevant to my research interests. The keywords are displayed as
    badges, and are styled using TailwindCSS.
    
    This component imports some statistics from the statisticsStore.js file,
    which is responsible for fetching the statistics from cache and api calls.

-->

<script>
  import {
    publicationCount,
    citations,
    hIndex,
  } from "../stores/statisticsStore.js";
</script>

<div class="mt-8">
  <h2 class="text-xl text-slate-300 font-bold mb-4">Abstract</h2>
  <!-- Your mini-profile content goes here -->
  <p class="text-slate-400 mb-4">
    Research grunt by day, something of a data scientist by night. I'm
    interested in applying computational solutions to social science problems
    while understanding and improving business sustainability writ large. In
    terms of numbers, my work has resulted in
    <strong> {$citations ?? 39}</strong>
    citations from
    <strong>{$publicationCount ?? 9}</strong> publications with an h-index of
    <strong>{$hIndex ?? 2}</strong> as listed on Google Scholar.
  </p>
</div>
<div class="mt-8">
  <h2 class="text-xl text-slate-300 font-bold mb-4">Keywords</h2>
  <p class="text-slate-400 mb-4">
    <span class="keyword">natural_language_processing</span>
    <span class="keyword">networks</span>
    <span class="keyword">sustainable strategy</span>
    <span class="keyword">analytics</span>
    <span class="keyword">critical raw materials</span>
  </p>
</div>

<style>
  .keyword {
    box-sizing: border-box; /* include padding and border */
    background-color: rgba(74, 222, 128, 0.04); /* Accent color for the box */
    color: #34d399; /* Color of the monospaced text */
    /* font-family: "Alegreya Sans", monospace; /* Monospaced font */
    font-size: 0.8em; /* Font size */
    padding: 4px 8px; /* Spacing inside the box */
    margin-right: 4px; /* Space between boxes */
    margin-bottom: 4px; /* Space between boxes */
    border-radius: 4px; /* Rounded corners */
    /* border: 1px solid rgba(52, 211, 153, 0.1); /* Border around the box */
    /* border: 1px solid rgba(74, 222, 128, 0.15); /* Border around the box */
    white-space: nowrap; /* Don't wrap the text */
    display: inline-block; /* Display as inline-block */
  }
</style>
