<!--
  Commits.svelte

  @description
    This component fetches the commit counts from the API endpoint and stores
    them in a store. It also updates the local storage with the latest values
    every 10 minutes of the page being open.

-->
<script>
  import { onMount } from "svelte";
  import { drawContributions } from "github-contributions-canvas";

  let canvasEl;
  let contributions = null;

  onMount(async () => {
    try {
      console.log("Fetching GitHub contributions...");
      const response = await fetch("/api/github-contributions");
      const data = await response.json();
      console.log("GitHub contributions fetched:", data);

      console.log("Drawing GitHub contributions...");
      // Filter the last two years
      contributions = {
        years: data.years.slice(0, 2),
        contributions: data.contributions.filter((contribution) => {
          const date = new Date(contribution.date);
          return date.getFullYear() >= new Date().getFullYear() - 1;
        }),
      };

      drawContributions(canvasEl, {
        data: contributions,
        username: "JPK85",
        themeName: "blue",
        footerText:
          "Powered by: https://github.com/sallar/github-contributions-canvas",
      });
    } catch (error) {
      console.error("Error fetching and drawing GitHub contributions:", error);
    }
  });
</script>

<div class="canvas-container">
  <canvas id="github-graph" bind:this={canvasEl} />
</div>

<style>
  .canvas-container {
    display: flex;
    justify-content: flex-center;
    align-items: center;
    backdrop-filter: blur(5px); /* Apply a 5px blur */
    /* background-color: rgba(0, 77, 102, 0.4); /* Dark teal/blueish color with 40% opacity */
    box-shadow: inset 0 0 15px 14px rgba(0, 0, 0, 0.1);
    border-radius: 30px; /* Rounded corners */
    overflow: hidden; /* To make sure the canvas corners are also rounded */
    padding: 10px; /* Adjust padding as needed */
    box-shadow: 0 14px 16px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for depth */
    margin-left: auto;
    margin-right: auto;
    max-width: 100%; /* Optional: Only needed if you want to limit the width */
    box-sizing: border-box;
  }

  #github-graph {
    max-width: 100%; /* Ensure it doesn't overflow its parent container */
    display: block; /* Removes any default margins or spacing */
    border-radius: 20px; /* Rounded corners */
  }
</style>
