<!--
  Citations.svelte

  @description
    This component fetches the citation counts from the API endpoint and stores
    them in a store. It also updates the local storage with the latest values
    every 10 minutes of the page being open.
-->
<script>
  import { onMount } from "svelte";
  import { citations } from "../stores/statisticsStore.js";

  onMount(() => {
    const fetchData = async () => {
      const response = await fetch("/api/citations");
      const data = await response.json();
      // console.log(`Citations data from API endpoint: ${JSON.stringify(data)}`);

      // Currently this is still dual responsibility
      // Both fresh and .db cached values are returned
      // The store initializes `citations` with the DB values now
      if (data.citations !== null) {
        citations.set(data.citations);
      }

      // Update local storage
      localStorage.setItem("citations", JSON.stringify(data.citations));
    };

    fetchData(); // Call once on component mount

    const intervalID = setInterval(fetchData, 10 * 60 * 1000); // Call every 10 minutes

    return () => clearInterval(intervalID); // Clear interval on component unmount
  });
</script>
