<!--
  Recents.svelte

  @description
    This component renders the most recent three publications from the
    publication list. It uses the `publicationList` store to render the
    list of publications. The store is populated by the `Publications.svelte`
    component. The full list is rendered inside `Research.svelte` by the
    `PubList.svelte` component.

-->
<script>
  import {
    publicationList,
    cachedPublicationList,
    isFetchingPubList,
  } from "../stores/statisticsStore.js";

  // Initially, set the recentPublications to the first three items from the cached list.
  let recentPublications = $cachedPublicationList.slice(0, 3);

  // Watch for changes in the live publication list and update recentPublications accordingly.
  $: if ($publicationList.length > 0) {
    recentPublications = $publicationList.slice(0, 3);
  }
</script>

<div>
  <h2 class="text-4xl text-slate-300 font-bold mb-10 mt-20">
    Recent Publications
  </h2>

  {#if $isFetchingPubList}
    <p>Loading...</p>
  {:else}
    <div class="research-list">
      {#each recentPublications as pub}
        <a href={pub.link ? pub.link : "#"} class="publication-item">
          <h3 class="text-xl text-slate-400 font-bold">
            <span>{pub.title}</span>
          </h3>
          <p class="text-md text-slate-300">{pub.authors}</p>
          <p class="text-sm text-slate-200">
            {pub.year},
            {pub.publicationDetails}
          </p>
          <p class="text-sm text-slate-400">
            {pub.citedBy} Google Scholar citations
          </p>
        </a>
      {/each}
    </div>
  {/if}
</div>
