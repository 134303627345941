<!--
  MarkdownRenderer.svelte

  @description
    This component is responsible for rendering markdown text. It uses the
    `marked` library to parse the markdown and render it as HTML. It also
    uses `highlight.js` to highlight code blocks, and katex to render LaTeX
    math blocks. ESLint doesn't like the @html directive as it is a potential
    XSS vulnerability, but we are using it here to render the HTML generated
    by marked. This is safe as we are not rendering any user input, but
    rather markdown text that is fetched from the server. The component
    also uses a custom renderer to customize how the markdown is rendered.
    This is done to make the markdown look more akin to the rest of the site,
    with a bit of a twist to stand out.

-->
<script>
  import { marked } from "marked";
  // import { fontConfig } from "../../font-config";
  import hljs from "highlight.js";
  import katex from "katex";
  import "katex/dist/katex.min.css";

  // Set current serif font from fontConfig
  // const serifFontKey = Object.keys(fontConfig.serif)[0];
  // const serifFont = fontConfig.serif[serifFontKey].displayName;

  marked.use({
    gfm: true,
  });

  // Create a new renderer
  const renderer = new marked.Renderer();

  // Customize how code blocks are rendered
  renderer.code = function (code, language) {
    // Check if the language specified is 'math' for LaTeX
    if (language === "math") {
      try {
        const renderedLatex = katex.renderToString(code, {
          throwOnError: false,
        });
        return `<div class="text-slate-300 text-center">${renderedLatex}</div>`;
      } catch (err) {
        console.error(err);
        return code; // Return raw code on failure
      }
    }

    const validLang = !!(language && hljs.getLanguage(language));
    const highlighted = validLang ? hljs.highlight(language, code).value : code;
    return `<pre class="rounded-md my-4 shadow-2x1"><code class="hljs ${language} p-4 border shadow-2x1 rounded-md text-xs">${highlighted}</code></pre>`;
  };

  // Code spans
  renderer.codespan = function (text) {
    // Detect and render LaTeX wrapped in @@
    if (text.startsWith("@@") && text.endsWith("@@")) {
      const latex = text.slice(2, -2); // Remove $$ delimiters
      try {
        const renderedLatex = katex.renderToString(latex, {
          throwOnError: false,
        });
        return `<span class="text-slate-300 text-sm">${renderedLatex}</span>`;
      } catch (err) {
        console.error(err);
        return text; // Return raw text on failure
      }
    }

    // return `<code class="text-sm text-slate-200 bg-slate-900 p-1 rounded font-mono">${text}</code>`;
    return `<code class="keyword-small">${text}</code>`;
  };

  // Customize how links are rendered
  renderer.link = function (href, title, text) {
    return `<a href="${href}" title="${
      title || ""
    }" class="text-emerald-400 font-bold hover:text-slate-200 visited:text-emerald-500">${text}</a>`;
  };

  // Customize how unordered lists are rendered
  renderer.list = function (body, ordered) {
    const type = ordered ? "ol" : "ul";
    const classes = ordered
      ? "list-decimal list-inside"
      : "list-disc list-inside";
    return `<${type} class="${classes} text-slate-400">${body}</${type}>`;
  };

  // Customize how list items are rendered
  renderer.listitem = function (text) {
    return `<li class="mb-1 mt-1 text-slate-400">${text}</li>`;
  };

  // Customize how blockquotes are rendered
  renderer.blockquote = function (quote) {
    return `<blockquote class="border-l-4 my-4 border-slate-400 pl-4 text-slate-500 italic font-serif">${quote}</blockquote>`;
  };

  // Horizontal rules
  renderer.hr = function () {
    return `<hr class="my-4 border-t border-slate-400">`;
  };

  // Customize how table cells are rendered
  renderer.tablecell = function (content, flags) {
    const type = flags.header ? "th" : "td";
    const classes = flags.header
      ? "px-5 py-2 text-left text-xs font-medium text-slate-400 uppercase tracking-wider"
      : "px-5 py-2 text-sm text-slate-400";
    return `<${type} class="${classes}">${content}</${type}>`;
  };

  // Customize how tables are rendered
  renderer.table = function (header, body) {
    return `<table class="min-w-full divide-y divide-slate-200 text-slate-400 my-4"><thead class="border bg-slate-900 text-slate-100">${header}</thead><tbody>${body}</tbody></table>`;
  };

  // Customize how headers are rendered
  renderer.heading = function (text, level) {
    const classes = {
      1: "text-4xl my-4 font-serif font-bold text-slate-200",
      2: "text-3xl my-4 font-serif font-semibold text-slate-300",
      3: "text-2xl my-4 font-serif font-medium text-slate-300",
    };
    return `<h${level} class="${classes[level] || ""}">${text}</h${level}>`;
  };

  // Customize how paragraphs are rendered
  renderer.paragraph = function (text) {
    return `<p class="text-md my-4 font-serif text-slate-400">${text}</p>`;
  };

  // Customize how strong | bold text is rendered
  renderer.strong = function (text) {
    return `<span class="font-serif font-extrabold text-slate-300">${text}</span>`;
  };

  renderer.em = function (text) {
    return `<em style=font-style: italic;">${text}</em>`;
  };

  // Configure marked to use this renderer
  marked.setOptions({
    renderer: renderer,
    highlight: function (code, lang) {
      const validLang = hljs.getLanguage(lang) ? lang : "plaintext";
      return hljs.highlight(validLang, code).value;
    },
  });

  export let markdownText = "";

  // $: console.log("Exporting markdownText:", markdownText);
</script>

<div class="markdown-content">
  {@html marked(markdownText)}
</div>

<style>
  :global(.keyword-small) {
    box-sizing: border-box; /* include padding and border */
    background-color: rgba(74, 222, 128, 0.04); /* Accent color for the box */
    color: #34d399; /* Color of the monospaced text */
    /* font-family: "Alegreya Sans", monospace; /* Monospaced font */
    font-size: 0.8em; /* Font size */
    padding: 2px 2px; /* Spacing inside the box */
    margin-right: 2px; /* Space between boxes */
    margin-left: 2px; /* Space between boxes */
    margin-bottom: 0px; /* Space between boxes */
    border-radius: 4px; /* Rounded corners */
    /* border: 1px solid rgba(52, 211, 153, 0.1); /* Border around the box */
    /* border: 1px solid rgba(74, 222, 128, 0.15); /* Border around the box */
    white-space: nowrap; /* Don't wrap the text */
    display: inline-block; /* Display as inline-block */
  }

</style>
