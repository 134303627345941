<!--
  Socials.svelte

  @description
    This component renders the social media icons and their links.
    It also listens to the `highlightSocialsStore` store to toggle
    the highlighted effect on the socials div.

-->
<script>
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
  import { faTwitter, faGithub } from "@fortawesome/free-brands-svg-icons";
  import { faResearchgate } from "@fortawesome/free-brands-svg-icons";
  import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
  import { highlightSocialsStore } from "../stores/socialsStore.js";

  let highlighted = false;

  highlightSocialsStore.subscribe((value) => {
    highlighted = value;
  });
</script>

<div
  class="socials flex space-x-5 justify-center"
  id="socials"
  class:highlighted
>
  <a
    href="https://twitter.com/JaanPauli"
    target="_blank"
    rel="noopener
        noreferrer"
    title="Twitter"
    class="text-slate-300
        hover:text-emerald-400 visited:text-slate-400 fa-lg"
  >
    <FontAwesomeIcon icon={faTwitter} />
  </a>
  <a
    href="https://github.com/JPK85"
    target="_blank"
    rel="noopener noreferrer"
    title="Github"
    class="text-slate-300 hover:text-emerald-400
        visited:text-slate-400 fa-lg"
  >
    <FontAwesomeIcon icon={faGithub} />
  </a>
  <a
    href="https://scholar.google.com/citations?user=wZDrIvgAAAAJ&hl=en"
    title="Google Scholar"
    class="text-slate-300 hover:text-emerald-400
        visited:text-slate-400 fa-lg"
  >
    <FontAwesomeIcon icon={faGraduationCap} />
  </a>
  <a
    href="https://www.researchgate.net/profile/Jaan_Pauli_Kimpimaeki"
    title="ResearchGate"
    class="text-slate-300 hover:text-emerald-400
        visited:text-slate-400 fa-lg"
  >
    <FontAwesomeIcon icon={faResearchgate} />
  </a>
  <a
    href="https://www.linkedin.com/in/jpkimpimaki/"
    title="LinkedIn"
    class="text-slate-300 hover:text-emerald-400
        visited:text-slate-400 fa-lg"
  >
    <FontAwesomeIcon icon={faLinkedin} />
  </a>
</div>

<style>
  /* Initial invisible border, and any other styles related to the socials div */
  .socials {
    border: 1px solid transparent;
    padding: 20px;
    margin: -10px; /* Negative margin to make the border appear outside the div */
    transition: opacity 0.5s ease;
    opacity: 1;
  }

  /* Styling for the highlighted effect, combined with original class */
  .socials.highlighted {
    border-color: rgba(255, 255, 255, 0.7); /* Translucent white border */
    border-radius: 15px; /* Adding rounded corners */
    padding: 10px; /* Some padding around the icons */
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.4); /* A subtle glow effect */
    margin: 0; /* Resetting the negative margin from the original class */
    opacity: 0.5;
  }
</style>
