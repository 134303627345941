<!--
  BlogPostFetcher.svelte

  @description
    This component is a simple fetcher that fetches a blog post from the server
    and passes it to the slot. It also contains a loading indicator while the
    markdown is being fetched, and an optional debugging print statement.
-->
<script>
  export let url = "";
  let text = "";

  async function fetchText(currentUrl) {
    const response = await fetch(currentUrl);
    text = await response.text();
  }

  $: if (typeof fetchText === "function") fetchText(url);
  // $: console.log("Fetched text:", text);
</script>

<slot {text} />
