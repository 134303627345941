<!--
  BlogPost.svelte

  @description
    This component is the blog post page. It imports a markdown renderer
    that fetches the markdown from the server and renders it. It also
    contains a loading indicator while the markdown is being fetched.

-->
<script>
  import MarkdownRenderer from "./MarkdownRenderer.svelte";
  import BlogPostFetcher from "./BlogPostFetcher.svelte";
  import { faAudioDescription } from "@fortawesome/free-solid-svg-icons";
  export let url = "";
  let markdownText;
  // $: console.log("Blogpost received", markdownText);
</script>

<BlogPostFetcher {url} let:text={markdownText}>
  {#if markdownText}
    <MarkdownRenderer {markdownText} />
  {:else}
    <div
      class="flex text-3xl justify-center items-center text-slate-600 font-bold"
    >
      Loading...
    </div>
  {/if}
</BlogPostFetcher>
