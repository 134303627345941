<!--
  About.svelte

  @description
    This component is the about page. It contains a summary of my profile, and
    a graph of my commit activity over the last couple of years.

-->
<script>
  import ProfileText from "./ProfileText.svelte";
  import Commits from "./Commits.svelte";
  import { onMount } from "svelte";

  onMount(() => {
    const scrollableDiv = document.getElementById("dynamic-content");
    if (window.innerWidth <= 1024) {
      // Or whatever your mobile breakpoint is
      // For smaller screens, scroll the whole window
      if (scrollableDiv) {
        const rect = scrollableDiv.getBoundingClientRect();
        window.scrollTo({
          top: rect.top + window.scrollY,
          behavior: "smooth",
        });
      }
    } else {
      // For larger screens, scroll only the div
      if (scrollableDiv) {
        scrollableDiv.scrollTop = 0;
      }
    }
  });
</script>

<div class="mb-8">
  <ProfileText />
  <p class="text-slate-300 mb-4">
    The below graph shows my primary repository commit activity over the last
    couple of years. The commit numbers are likely a better performance
    indicator than whatever numbers I have chosen to include on the left hand
    side summary.
  </p>
  <Commits />
  <p class="text-slate-300 mt-4 mb-4">
    <strong>Note:</strong> the above contains both work-related and personal commits
    to master branches. The ratio of personal-to-professional is anyone's guess,
    and most of my work is done off-master.
  </p>
  <div class="border-b mb-8 border-separator mt-20" />
</div>
