<!--
  HoverEffect.svelte

  @description
    This component is a simple hover effect that applied to all elements. This
    highjacks the cursor and applies a radial gradient for a small glow effect
    on hover. The purpose is to function as a searchlight highlighting better
    where the user is navigating.

 -->

<script>
  let cursor;

  function handleMouseMove(event) {
    const x = event.clientX;
    const y = event.clientY;

    cursor.style.left = `${x}px`;
    cursor.style.top = `${y}px`;
    cursor.style.display = "block";
  }

  import { onMount } from "svelte";

  onMount(() => {
    cursor = document.getElementById("cursor");
    window.addEventListener("mousemove", handleMouseMove);
  });
</script>

<div id="cursor" />

<style>
  #cursor {
    position: fixed;
    width: 600px;
    height: 600px;
    border-radius: 90%;
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.3) 5%,
        transparent 20%
      ),
      radial-gradient(circle, rgba(255, 255, 255, 0.2) 20%, transparent 40%),
      radial-gradient(circle, rgba(255, 255, 255, 0.1) 40%, transparent 70%),
      radial-gradient(circle, rgba(255, 255, 255, 0.05) 70%, transparent 100%);
    mix-blend-mode: overlay;
    transform: translate(-50%, -50%);
    pointer-events: none;
    display: none;
  }
</style>
