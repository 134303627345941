<script>
  import "./styles/app.css";
  // Decent style names include: base16/ashes, base16/atlas, atom-one-dark-reasonable
  // base16/darkmoss, base16/dracula, base16/flat | eva | eva-dim (BEST),
  // base16/harmonic16-dark, base16/icy-dark, base16/material-palenight,
  // base16/nebula, base16/nord, base16/paraiso, base16/pasque, base16/phd,
  // base16/rebecca, base16/ros-pine-moon, base16/solarized-dark,
  import "highlight.js/styles/base16/harmonic16-dark.css";
  import FontFaces from "./components/FontFaces.svelte";
  import HoverEffect from "./components/HoverEffect.svelte";
  import About from "./components/About.svelte";
  import Research from "./components/Research.svelte";
  import Citations from "./components/Citations.svelte";
  import Hindex from "./components/Hindex.svelte";
  import Publications from "./components/Publications.svelte";
  import Recents from "./components/Recents.svelte";
  import BlogPostCarousel from "./components/BlogPostCarousel.svelte";
  import Footer from "./components/Footer.svelte";
  import LeftSide from "./components/LeftSide.svelte";
  import { Router, Route } from "svelte-routing";
  import { inject } from "@vercel/analytics";

  // Inject analytics tracking
  inject();
</script>

<Router>
  <div class="flex flex-col lg:flex-row lg:h-screen">
    <div class="utility-components">
      <!-- Utility components go here -->
      <FontFaces />
      <HoverEffect />
      <Publications />
      <Citations />
      <Hindex />
    </div>
    <!-- Left Side (1/3) for Navbar and Abstract -->
    <LeftSide />
    <!-- Right Side (2/3) for Main Content -->
    <div
      class="w-full lg:w-2/3 lg:ml-1/3 p-16 lg:pl-40 lg:pt-20 lg:pr-40
        lg:pb-16 lg:overflow-y-auto"
      id="dynamic-content"
    >
      <!-- Dynamic content / components here -->
      <Route exact path="/">
        <About />
        <Recents />
      </Route>
      <Route exact path="/about">
        <About />
        <Recents />
      </Route>
      <Route exact path="/research" component={Research} />
      <Route exact path="/annotations" component={BlogPostCarousel} />
      <Route path="/annotations/:url" let:params>
        <BlogPostCarousel initialUrl={"/annotations/" + params.url} />
      </Route>
    </div>
  </div>
  <Footer />
</Router>

<style>
  /* Styles here if any */
</style>
