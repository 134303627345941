<!--
  LeftSide.svelte

  @description
    This component handles the layout and styling for the left side div
    that contains the profile text, name with CV link, and navbar with
    socials.

    It imports the following components:
      - HoverEffect.svelte // general hover effect for name
      - MiniProfile.svelte // mini profile text with dynamic content
      - NavBar.svelte // navbar with links to other pages
      - Socials.svelte // socials with icons
      - FontAwesomeIcon from @fortawesome/free-solid-svg-icons
      - faFileAlt from @fortawesome/free-solid-svg-icons

-->

<script>
  import HoverEffect from "./HoverEffect.svelte";
  import MiniProfile from "./MiniProfile.svelte";
  import NavBar from "./NavBar.svelte";
  import Socials from "./Socials.svelte";
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

  // Initialize hover state
  let hover = false;
</script>

<div>
  <HoverEffect />
</div>
<div class="w-full lg:w-1/3 overflow-y-auto">
  <div class="p-16 flex items-center justify-center lg:h-screen">
    <div
      class="p-8 lg:border-r border-b border-r-0 lg:border-b-0 border-separator"
    >
      <!-- Profile name header -->
      <div class="">
        <h1
          class="text-4xl text-slate-200 font-semibold mb-8
                        hover-container"
        >
          <span
            class="name-wrapper flex items-center
                            justify-center relative"
            role="button"
            on:mouseover={() => (hover = true)}
            on:mouseout={() => (hover = false)}
            on:focus={() => (hover = true)}
            on:blur={() => (hover = false)}
            tabindex="0"
          >
            <span
              class="{hover ? 'hidden' : 'visible'}
                                absolute overlay"
            >
              Jaan-Pauli Kimpimäki</span
            >
            <span
              class="cv {hover ? 'visible' : 'hidden'}
                                absolute overlay flex items-left"
            >
              <a
                href="https://www.dropbox.com/scl/fi/ytwx0z9zo950kfnrmog1v/Academic_CV.pdf?rlkey=tbg9fnfahq2i6sai1wxzx39zy&dl=0"
                title="CV"
                alt="Curriculum Vitae"
                class="text-slate-300 hover:text-slate-200
                                    visited:text-slate-400 fa-lg"
              >
                <FontAwesomeIcon icon={faFileAlt} />
                <span class="cv-text">CV</span>
              </a>
            </span>
          </span>
        </h1>
        <!-- Mini profile goes here -->
        <MiniProfile />
        <div>
          <!-- Navbar goes here -->
          <NavBar />
        </div>
        <div class="mt-4 text-slate-300">
          <!-- Socials go here with icons -->
          <Socials />
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .hover-container {
    cursor: pointer;
  }
  .hidden {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .visible {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  .name-wrapper {
    position: relative;
    height: 50px; /* Fixed height */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .overlay {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .cv-text {
    margin-left: 12px;
    font-size: 1.1em; /* Adjust size as needed */
  }
</style>
