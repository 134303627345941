// font-config.js
export const fontConfig = {
  sans: {
    // opts: manrope, quicksand, inter, raleway
    // montserrat, open-sans, lato, outfit
    outfit: {
      path: "node_modules/@fontsource/outfit/files",
      displayName: "Outfit",
    },
  },
  serif: {
    Lora: {
      path: "node_modules/@fontsource/lora/files",
      displayName: "Lora",
    },
  },
  // mono: {
  //   "Source-code-pro": "node_modules/@fontsource/source-code-pro/files",
  // },
};
