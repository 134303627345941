<!--
  Research.svelte

  @description
    This component is responsible for rendering the research page. It contains
    the full publication list fetched from the API endpoint and stored in the
    `publicationList` store. The rendering of the list is delegated to the
    `PubList.svelte` component. In addition, there is a small text description
    of current research activities.

-->
<script>
  import PubList from "./PubList.svelte";
  import { onMount } from "svelte";
  import { highlightSocialsStore } from "../stores/socialsStore.js";

  function highlightSocials() {
    highlightSocialsStore.set(true);
  }

  function removeHighlight() {
    highlightSocialsStore.set(false);
  }


  onMount(() => {
    const scrollableDiv = document.getElementById("dynamic-content");
    if (window.innerWidth <= 1024) {
      // Or whatever your mobile breakpoint is
      // For smaller screens, scroll the whole window
      if (scrollableDiv) {
        const rect = scrollableDiv.getBoundingClientRect();
        window.scrollTo({
          top: rect.top + window.scrollY,
          behavior: "smooth",
        });
      }
    } else {
      // For larger screens, scroll only the div
      if (scrollableDiv) {
        scrollableDiv.scrollTop = 0;
      }
    }
  });
</script>

<div>
  <h1 class="text-5xl text-slate-300 font-bold mb-8">Research</h1>
<p class="text-slate-300 mb-4">
    I'm currently a post-doctoral researcher at <a
      href="https://www.lut.fi/en/about-lut/faculties/lut-business-school"
      >LUT Business School (LBS)</a
    >, affiliated with the multidisciplinary
    <a
      href="https://www.lut.fi/en/research/search-research/platforms/sci-mat-sustainable-circularity-inorganic-materials"
      >Sustainable Circularity of Inorganic Materials (SCI-MAT) strategic
      research platform</a
    >, and manager of the Academy of Finland project
    <a href="https://www.action4commons.fi/"
      >Collective stakeholder action for commons: Integrating
      business-stakeholder value creation with polycentric governance
      (Action4Commons)</a
    >. I've also been an active participact and member of the
    <a href="https://mediacontradictions.com/">Media Contradictions </a>
    project. The
    <strong>Keywords</strong> list on the left should give you a good idea of what I'm primarily interested in research-wise.
  <p class="text-slate-300 mb-4">
    My current research activities are quite varied. Among other things, I work
    with supply chain networks and varied text mining applications. Ongoing
    work is focused on emissions decoupling in supply networks, understanding
    the skill gaps between industry and academia, and understanding distinct
    linguistic patterns in the way media industry frames its sustainability and
    CSR activities. As part of my project work, I am also modelling the
    discourse surrounding the concept of biodiversity across policy and
    academic domains.
  </p>
  <p class="text-slate-300 mb-4">
    I am also continuously attempting to solve the many mysteries related to the term <strong
      >computational abduction</strong
    > which I began developing as part of my doctoral dissertation.
  </p>
  <p class="text-slate-300 mb-4">
    In addition to the above, I am continuously working with critical raw
    materials topics and their relationship with circularity, as well as other
    topics related to strategy, innovation, management, and sustainability in
    general.

    My <strong
      ><a
        href="#socials"
        on:mouseover={highlightSocials}
        on:mouseout={removeHighlight}
        on:focus={highlightSocials}
        on:blur={removeHighlight}>socials</a
      ></strong
    > include Scholar and ResearchGate profiles for more information on my
  latest activities.

  Below, you'll find a Google Scholar fetched list of my publications roughly
    ranked in order of yearly publication appearance.
  </p>
  <div class="border-b mb-8 border-separator mt-20" />
  <h2 class="text-5xl text-slate-300 font-bold mb-8 mt-20">Publications</h2>
  <PubList />
</div>

<style>
  a {
    @apply text-slate-500 font-bold;
  }

  a:hover {
    @apply text-emerald-400 font-bold;
  }

  a:visited {
    @apply text-slate-400 font-bold;
  }
</style>
